<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card>
        <v-card-title>Lista de alertas</v-card-title>

        <v-card-text class="mt-8">
          <v-data-table :items="items" :headers="headers">
            <template v-slot:[`item.value_in_momment`]="{ item }">
              {{ parseFloat(item.value_in_momment).toFixed(3)
              }}{{ " " + item.payload }}
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ fecha(item.createdAt) }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="secondary" @click="close()" dark> Cancelar </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="save()" dark> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import BackendApi from "@/services/backend.service";
import moment from "moment";

export default {
  data() {
    return {
      dialog: false,
      headers: [
        { text: "SeqNumber", value: "seqNumber" },
        { text: "Valor", value: "value_in_momment" },
        { text: "Evento", value: "createdAt" },
      ],
      items: [],
      devices: [],
      alertsData: [],
      alert: {},
    };
  },
  computed: {
    isTelemetry() {
      if (!this.deviceClass) return false;

      return this.deviceClass.telemetry;
    },
  },
  methods: {
    fecha(fecha) {
      return moment(fecha).format("DD/MM/YYYY HH:mm:ss");
    },
    open(item) {
      this.alert = item;
      this.dialog = true;
      this.getProductos();
    },
    close() {
      this.dialog = false;
    },
    getProductos() {
      BackendApi.get("/customAlert/customAlertData/" + this.alert.id)
        .then((response) => {
          if (response.data.success) {
            this.items = response.data.data;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          
        });
    },
  },
};
</script>

<style></style>
