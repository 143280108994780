<template>
  <div>
    <v-row class="pa-8 px-8">
      <v-spacer></v-spacer>
      <v-btn class="mr-2" color="primary" dark @click="newAlert()">
        <v-icon>fa-plus</v-icon>
      </v-btn>
    </v-row>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar en alertas ..."
          single-line
          hide-details
          outlined
        >
        </v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table :items="items" :headers="headers" :search="search">
          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ fecha(item.updatedAt) }}
          </template>
          <template v-slot:[`item.valor`]="{ item }">
            {{ item.valor }}
            {{ item.unidad == 1 || item.unidad == 3 ? "M3" : "kW-h" }}
          </template>
          <template v-slot:[`item.condicion`]="{ item }">
            <p v-if="item.condicion === '1'">></p>
            <p v-if="item.condicion === '2'">{{ "<" }}</p>
            <p v-if="item.condicion === '3'">=</p>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" v-on="on"> Acciones </v-btn>
              </template>

              <v-list>
                <v-list-item link @click="openUpdateUser(item)">
                  Modificar
                </v-list-item>
                <template>
                  <div class="text-center">
                    <v-dialog
                      v-model="dialogConfirm"
                      persistent
                      max-width="400"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item link v-bind="attrs" v-on="on">
                          Eliminar
                        </v-list-item>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5">
                          Seguro de eliminar este usuario?
                        </v-card-title>
                        <v-card-text
                          >Una vez eliminado este usuario, no podrá recuperarlo.
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="secondary"
                            text
                            @click="dialogConfirm = false"
                          >
                            Cancelar
                          </v-btn>
                          <v-btn
                            color="primary"
                            dark
                            text
                            @click="deleteUser(item)"
                          >
                            Confirmo
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </template>
                <v-list-item link @click="showCustomSlerData(item)">
                  Alertas
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <NewCustomAlert ref="newCustomAlert" @onNewCustomAlert="onNewCustomAlert" />
    <ShowCustomAlertComponent ref="showCustomAlertComponent" />
  </div>
</template>

<script>
import BackendApi from "@/services/backend.service";
import NewCustomAlert from "./Components/customAlertComponent/NewCustomAlert.vue";
import ShowCustomAlertComponent from "./Components/customAlertComponent/ShowCustomAlertData.vue";
// import xlsx from "json-as-xlsx";
import moment from "moment";

export default {
  components: {
    NewCustomAlert,
    ShowCustomAlertComponent,
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Dispositivo", value: "external_device_id" },
        { text: "Condicion", value: "condicion" },
        { text: "Consumo diario", value: "valor" },
        { text: "Últ. modificación", value: "updatedAt" },
        { text: "Accion", value: "actions" },
      ],
      dialogConfirm: false,
      items: [],
    };
  },
  created() {},
  mounted() {
    this.getAlertDevice();
  },
  computed: {},
  methods: {
    fecha(fecha) {
      return moment(fecha).format("DD/MM/YYYY HH:MM:SS");
    },
    getAlertDevice() {
      BackendApi.get("/customAlert/customAlert/")
        .then((response) => {
          if (response.data.success) {
            this.items = response.data.data;
            console.log(this.items);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    newAlert() {
      this.$refs.newCustomAlert.open();
    },
    openUpdateUser(item) {
      this.$refs.newCustomAlert.update(item);
    },
    deleteUser(item) {
      BackendApi.delete("/customAlert/customAlert/" + item.id)
        .then((response) => {
          if (response.data.success) {
            this.dialogConfirm = false;
            this.getAlertDevice();
          }
        })
        .catch((error) => {
          this.isLoading = false;

          if (error.response.data.errors) {
            this.errores[error.response.data.errors[0]?.param] =
              error.response.data.errors[0]?.msg;
          } else {
            this.errores.name = error.response.data?.msg;
          }
        });
    },
    showCustomSlerData(item) {
      this.$refs.showCustomAlertComponent.open(item);
    },
    onNewCustomAlert() {
      this.getAlertDevice();
    },
  },
};
</script>
