var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"pa-8 px-8"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.newAlert()}}},[_c('v-icon',[_vm._v("fa-plus")])],1)],1),_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar en alertas ...","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"search":_vm.search},scopedSlots:_vm._u([{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fecha(item.updatedAt))+" ")]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.valor)+" "+_vm._s(item.unidad == 1 || item.unidad == 3 ? "M3" : "kW-h")+" ")]}},{key:"item.condicion",fn:function(ref){
var item = ref.item;
return [(item.condicion === '1')?_c('p',[_vm._v(">")]):_vm._e(),(item.condicion === '2')?_c('p',[_vm._v(_vm._s("<"))]):_vm._e(),(item.condicion === '3')?_c('p',[_vm._v("=")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" Acciones ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openUpdateUser(item)}}},[_vm._v(" Modificar ")]),[_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_vm._v(" Eliminar ")])]}}],null,true),model:{value:(_vm.dialogConfirm),callback:function ($$v) {_vm.dialogConfirm=$$v},expression:"dialogConfirm"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Seguro de eliminar este usuario? ")]),_c('v-card-text',[_vm._v("Una vez eliminado este usuario, no podrá recuperarlo. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.dialogConfirm = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","dark":"","text":""},on:{"click":function($event){return _vm.deleteUser(item)}}},[_vm._v(" Confirmo ")])],1)],1)],1)],1)],_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.showCustomSlerData(item)}}},[_vm._v(" Alertas ")])],2)],1)]}}],null,true)})],1)],1),_c('NewCustomAlert',{ref:"newCustomAlert",on:{"onNewCustomAlert":_vm.onNewCustomAlert}}),_c('ShowCustomAlertComponent',{ref:"showCustomAlertComponent"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }