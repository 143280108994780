<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card>
        <v-card-title> Añadir Alerta </v-card-title>

        <v-card-text class="mt-8">
          <v-form @submit.prevent="save()" ref="formNewDevice">
            <v-row>
              <v-col>
                <v-select
                  v-model="customAler.deviceProductId"
                  label="Seleccione uno de nuestros productos"
                  :items="devices"
                  :rules="[(v) => !!v || 'Producto es requerido']"
                  outlined
                  item-text="alias"
                  item-value="id"
                  @change="getMeasurement_unit()"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="customAler.condicion"
                  label="Seleccione condicion"
                  :items="condicion"
                  :rules="[(v) => !!v || 'condicion es requerido']"
                  outlined
                  item-text="text"
                  item-value="value"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col lg="12" class="col-12 my-0 py-0">
                <v-text-field
                  v-model="customAler.value"
                  outlined
                  :label="Consumo"
                  :suffix="unidad"
                  :rules="[
                    (v) => !!v || 'Valor requerido',
                    (v) => v > 0 || 'El valor debe ser un numero',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="secondary" @click="close()" dark> Cancelar </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="save()" dark> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import BackendApi from "@/services/backend.service";

export default {
  data() {
    return {
      unidad: "",
      dialog: false,
      measurement_unit: "Valor",
      deviceSelected: {
        device_product: {
          device_class: {
            measurement_unit: "Valor",
          },
        },
      },
      isUpdate: false,
      devices: [],
      condicion: [
        {
          text: "> Mayor que",
          value: 1,
        },
        {
          text: "< Menor que",
          value: 2,
        },
        {
          text: "= Igual a",
          value: 3,
        },
      ],
      customAler: {
        deviceProductId: null,
        condicion: null,
        value: null,
      },
    };
  },
  created() {
    this.getProductos();
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$refs.formNewDevice.resetValidation();
      this.$refs.formNewDevice.reset();
    },
    update(item) {
      this.isUpdate = true;
      this.dialog = true;
      this.customAler.id = item.id;
      this.customAler.deviceProductId = item.deviceId;
      this.customAler.condicion = parseInt(item.condicion);
      this.customAler.value = item.valor;
    },
    save() {
      if (this.$refs.formNewDevice.validate()) {
        if (!this.isUpdate) {
          BackendApi.post("/customAlert/customAlert", this.customAler)
            .then((response) => {
              if (response.data.success) {
                this.$emit("onNewCustomAlert");
                this.close();
              }
            })
            .catch((error) => {
              this.isLoading = false;

              if (error.response.data.errors) {
                this.errores[error.response.data.errors[0]?.param] =
                  error.response.data.errors[0]?.msg;
              } else {
                this.errores.name = error.response.data?.msg;
              }
            });
        } else {
          BackendApi.put(
            "/customAlert/customAlert/" + this.customAler.id,
            this.customAler
          )
            .then((response) => {
              if (response.data.success) {
                this.$emit("onNewCustomAlert");
                this.close();
              }
            })
            .catch((error) => {
              this.isLoading = false;

              if (error.response.data.errors) {
                this.errores[error.response.data.errors[0]?.param] =
                  error.response.data.errors[0]?.msg;
              } else {
                this.errores.name = error.response.data?.msg;
              }
            });
        }
      }
    },
    getProductos() {
      BackendApi.get("/device/deviceForAlert")
        .then((response) => {
          if (response.data.success) {
            this.devices = response.data.data;
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getMeasurement_unit() {
      this.deviceSelected = this.devices.find(
        (device) => device.id == this.customAler.deviceProductId
      );
      // this.measurement_unit =
      //   this.deviceSelected.device_product.device_class.measurement_unit;
      this.unidad =
        this.deviceSelected.device_product.deviceClassId == 1 ||
        this.deviceSelected.device_product.deviceClassId == 3
          ? "m3"
          : "kW-h";
    },
  },
};
</script>

<style></style>
